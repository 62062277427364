import React, { PropsWithChildren } from "react"

import Head from "next/head"

export type MetadataConfig = {
  title: string
  description: string
  url: string
  coverImage: string
  type: string
  favicon?: string
}

type Props = {
  data: MetadataConfig
}

const Metadata: React.FC<PropsWithChildren<Props>> = ({ data, children }) => (
  <Head>
    <title>{data.title}</title>
    <meta content={data.title} name="title" />
    <meta content={data.description} name="description" />
    <link href={data.url} rel="canonical" />

    {data.favicon && <link href={data.favicon} rel="icon" />}

    {children && children}

    {/* Open Graph / Facebook */}
    <meta content={data.type} property="og:type" />
    <meta content={data.url} property="og:url" />
    <meta content={data.title} property="og:title" />
    <meta content={data.description} property="og:description" />
    <meta content={data.coverImage} property="og:image" />

    {/* Twitter */}
    <meta content="summary_large_image" property="twitter:card" />
    <meta content={data.url} property="twitter:url" />
    <meta content={data.title} property="twitter:title" />
    <meta content={data.description} property="twitter:description" />
    <meta content={data.coverImage} property="twitter:image" />
  </Head>
)

export default Metadata
